<template>
  <div class="mt-2 text-sm text-white lg:text-base [&_a]:font-bold [&_a]:underline">
    <p>
      För att kunna hjälpa dig med din gåva och informera dig om arbetet för djuren sparar
      vi uppgifterna du lämnar. Det görs enligt dataskyddsförordningen vilket innebär att
      vi garanterar att dina uppgifter skyddas och inte lämnas ut till någon annan. Läs
      mer i vår
      <a href="https://www.djurensratt.se/integritet">integritetspolicy</a>.
    </p>
  </div>
</template>

<template>
  <div class="flex items-center space-x-4" v-if="Array.isArray(showLanguages) && showLanguages.length > 0">
    <Globe class='text-blue' :className="classIcon" />
    <NuxtLink v-for="lang in showLanguages" :key="lang.label" :to="lang.url" :class="classText"
      class="flex cursor-pointer text-blue items-center font-serif text-base hover:text-blue-light">
      <span class="uppercase">{{ lang.native }}</span>
    </NuxtLink>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRuntimeConfig } from '#app';
import Globe from '@/components/atoms/Icons/Globe.vue';

defineProps({
  classIcon: String,
  classText: String
});

const config = useRuntimeConfig();

const languages = [
  {
    native: 'SWE', // 'Svenska'
    label: 'sv',
    url: config.public.langUrlSv,
  },
  {
    native: 'ENG', // 'English',
    label: 'en',
    url: config.public.langUrlEn,
  },
  {
    native: 'UKR', // 'Українська'
    label: 'uk',
    url: config.public.langUrlUk,
  }
]

const showLanguages = computed(() => {
  return languages.filter(lang => lang.label !== config.public.siteLang && lang.url);
})

</script>

<template>
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.0026 14.9723V10.993C28.9569 10.993 28.9569 10.993 28.9569 10.993C27.8592 10.993 26.8072 10.6729 25.9381 10.0783C24.6575 9.25497 23.7427 7.92855 23.4683 6.41918C23.3768 6.09901 23.3768 5.77884 23.3768 5.45866C23.3768 5.41293 23.3768 5.41293 23.3768 5.36719H19.3518V21.3757C19.3518 23.251 17.7967 24.8061 15.9214 24.8061C14.0461 24.8061 12.491 23.251 12.491 21.3757C12.491 19.5004 14.0461 17.9453 15.9214 17.9453C16.2873 17.9453 16.6075 17.9911 16.9734 18.1283V14.0118C16.6075 13.9661 16.2873 13.9203 15.9214 13.9203C11.8049 13.9203 8.51172 17.2592 8.51172 21.3757C8.51172 25.4922 11.8049 28.7854 15.9214 28.7854C20.0379 28.7854 23.3768 25.4922 23.3768 21.3757V13.1885C24.9319 14.332 26.8529 14.9723 28.9569 14.9723H29.0026Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup></script>
